<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-sm">
                <b-input-group class="mt-3">
                    <template v-slot:append>
                        <b-button v-bind:disabled="search.loading" v-on:click="submit">{{search.loading? "搜尋中…": "搜尋"}}</b-button>
                    </template>
                    <b-input-group-prepend>
                        <b-form-select
                                :options="search.primaryOptions"
                                v-model="search.primaryType"
                                v-bind:disabled="search.loading"
                        />
                    </b-input-group-prepend>
                    <b-form-input v-model="search.primaryValue" v-bind:disabled="search.loading" v-on:keypress.enter="submit"/>
                </b-input-group>
            </div>
            <div class="w-100"/>
            <div class="col-sm">
                <b-table-simple hover small caption-top responsive>
                    <caption>姓名搜尋時，『%』可以取代任何或沒有字元，『_』（底線）可取代任一個文字。<a href="https://www.1keydata.com/tw/sql/sql-wildcard.html">更多說明</a></caption>
                    <colgroup><col><col><col><col><col></colgroup>
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>狀態</b-th>
                            <b-th>名單</b-th>
                            <b-th>床位</b-th>
                            <b-th>學號</b-th>
                            <b-th>姓名</b-th>
                            <b-th>系級</b-th>
                            <b-th>電子郵件</b-th>
                            <b-th>手機</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="item in result" v-bind:key="item.bedHistoryId">
                            <b-th>{{item.status}}</b-th>
                            <b-th>{{item.serial}}</b-th>
                            <b-th>{{item.room}}</b-th>
                            <b-td>{{item.stu_id}}</b-td>
                            <b-td>{{item.name}}</b-td>
                            <b-td>{{item.unit}}</b-td>
                            <b-td>{{item.email}}</b-td>
                            <b-td>{{item.phone}}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tfoot>
                        <b-tr>
                            <b-td colspan="8" variant="secondary" class="text-right">
                                符合搜尋的有：<b>{{result.length}}</b> 項（最多50項）
                            </b-td>
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "findStudent",
        data: function () {
            return{
                search: {
                    loading: false,
                    searchWithRoom: false,
                    primaryType: "學號",
                    primaryValue: "",
                    primaryOptions: [
                        "姓名",
                        "學號",
                        "房間"
                    ],
                },
                result: [
                ]
            }
        },
        methods:{
            submit: function () {
                if(this.search.primaryType === "房間"){
                    this.search.primaryValue = this.search.primaryValue.toUpperCase();
                }
                axios.get('/api/user/search_Read', {
                    params: {
                        primaryType: this.search.primaryType,
                        primaryWord: this.search.primaryValue
                    }
                }).then((response)=>{
                    this.result = response.data.data;
                }).catch((error)=>{
                    this.$store.dispatch("verifyStatus");
                    if(error.response.status === 400 || error.response.data.hasOwnProperty('msg')){
                        window.alert(error.response.data.msg);
                    }else{
                        window.alert('不明的錯誤');
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>